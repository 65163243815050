@import "../styles/root";

@font-face {
  font-display: swap;
  font-family: "CodecWarm-Bold";
  src:
    url("../assets/fonts/codec/Codec Warm Bold/Codec-Warm-Bold.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm Bold/Codec-Warm-Bold.eot") format("eot");
}



@font-face {
  font-display: swap;
  font-family: "CodecWarm-ExtraBold";
  src:
    url("../assets/fonts/codec/Codec Warm ExtraBold/Codec-Warm-ExtraBold.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm ExtraBold/Codec-Warm-ExtraBold.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-Regular";
  src:
    url("../assets/fonts/codec/Codec Warm Regular/Codec-Warm-Regular.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm Regular/Codec-Warm-Regular.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-Thin";
  src:
    url("../assets/fonts/codec/Codec Warm Thin/Codec-Warm-Thin.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm Thin/Codec-Warm-Thin.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "CodecWarm-ExtraLight";
  src:
    url("../assets/fonts/codec/Codec Warm ExtraLight/Codec-Warm-ExtraLight.woff") format("woff"),
    url("../assets/fonts/codec/Codec Warm ExtraLight/Codec-Warm-ExtraLight.eot") format("eot");
}

@font-face {
  font-display: swap;
  font-family: "Open-Sans-Bold";
  src: url("../assets/fonts/open sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica";
  src: url("../assets/fonts/helvetica/Helvetica.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Frutiger-Bold";
  src: url("../assets/fonts/frutiger/Frutiger Bold Condensed/Frutiger-LT-Std-67-Bold-Condensed.otf") format('opentype');
}

@font-face {
  font-display: swap;
  font-family: "Frutiger";
  src: url("../assets/fonts/frutiger/Frutiger Condensed/Frutiger-LT-Std-57-Condensed.otf") format('opentype');
}

@font-face {
  font-display: swap;
  font-family: "Frutiger-Black-Condensed";
  src: url("../assets/fonts/frutiger/Frutiger Black Condensed/Frutiger-LT-Std-77-Black-Condensed.otf") format('opentype');
}

.Desktop36px_Title-section-primary02_Greyscale05_Black {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop24px_Title-article02_Greyscale05_Black {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop18px_Subtitle-article01_Primary1_Blue {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1554ff;
}

.Mobile14px_Body302_Greyscale04_Light-Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Mobile16px_Body102_Greyscale05_Black {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Note02_Greyscale04_Light-Black-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Note02_Greyscale03_Dark-Grey-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--dark-grey-8);
}

.Mobile34px_Title02_Greyscale05_Black {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile20px_Title-article02_Greyscale05_Black {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile14px_Body302_Greyscale05_Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--total-black-10);
}