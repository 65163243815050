:root {
  --dark-grey-8: #4c4c4e;
  --light-black-9: #1e1e1d;
  --total-black-10: #000;
  //--6-light-grey: #d1d2d4;
  //   --4-white: #fff;
  //   --2-dark-blue: #0140e9;
  //   --7-medium-grey: #abadaf;
  //   --11-error: #c71515;
  //   --13-success: #40ab00;
  //   --5-background: #f4f4f4;
  //   --12-warning: #de9521;
  //   --color-iveco-group-header-tab-style-navbar-tab-on: #eee;
  //   --color-xx-action-13-success: #40ab00;
}
