@import "../node_modules/@patternfly/pfelement-onhighway/dist/pfelement";
@import "./lib/css/slick/slick.scss";
@import "./lib/css/slick/slick-theme.scss";
@import "./styles/variables";
// initial theme -> to be configured in ThemeContext/index.js and index.html
@import "../node_modules/@patternfly/pfe-styles-onhighway/dist/pfe-theme--case";
/// ===================================================
/// CONTAINERS AND BACKGROUNDS. USAGE:
/// <div class="bg-default">
///   <div class="container">
///     <p>Content</p>
///   </div>
/// </div>
/// ===================================================

main {
  min-height: 100%;
  margin-top: 93.33px;
  @media (min-width: $grid-breakpoint--md) {
    margin-top: 150px;
  }
  @media (max-width: 1024px) {
    margin-top: 131px;
  }
  @media (max-width: 768px) {
    margin-top: 171px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 131px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 131px;
  }
}
.bg-default,
.bg-grey {
  padding: 60px 0;
}

.bg-default {
  + .bg-default {
    padding-top: 0;
  }
}

.bg-grey {
  background-color: #fafafa;
  + .bg-grey {
    padding-top: 0;
  }
}

.container,
.container-xl,
.container-fullwidth {
  margin: 0 auto;
}

.container {
  max-width: 320px;
  @media (min-width: 768px) {
    max-width: 1200px;
  }
}

.container-xl {
  max-width: 1680px;
}

.container-fullwidth {
  @media (min-width: 1920px) {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1680px;
  }
}

.container--no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid {
  max-width: 100%;
}

.container,
.container-xl,
.container-fluid {
  padding: 0 1rem;
  @media (min-width: 768px) {
    padding: 0 2rem;
  }
  // + .container,
  // .container-xl,
  // .container-fluid {
  //   padding-top: 15px;
  //   @media (min-width: 768px) {
  //     padding-top: 30px;
  //   }
  // }
  &.container--no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

/// ===================================================
/// HELPER MARGIN AND PADDING FUNCTIONS - SPACING
/// ===================================================
$sides: (
  t: top,
  l: left,
  b: bottom,
  r: right,
);
$values: (0, 5, 10, 15, 20, 25, 30, 40, 60, 80);
$breakpoints: (
  md: 768px,
  xl: 1200px,
  xxl: 1680px,
);
@each $val in $values {
  .p-#{$val} {
    padding: #{$val}px !important;
  }
  @each $bp-name, $bp-value in $breakpoints {
    .p-#{$bp-name}-#{$val} {
      @media (min-width: #{$bp-value}) {
        padding: #{$val}px !important;
      }
    }
  }
  @each $letter, $side in $sides {
    .p#{$letter}-#{$val} {
      padding-#{$side}: #{$val}px !important;
    }
    @each $bp-name, $bp-value in $breakpoints {
      .p#{$letter}-#{$bp-name}-#{$val} {
        @media (min-width: #{$bp-value}) {
          padding-#{$side}: #{$val}px !important;
        }
      }
    }
  }
}

@each $val in $values {
  .m-#{$val} {
    margin: #{$val}px !important;
  }
  @each $bp-name, $bp-value in $breakpoints {
    .m-#{$bp-name}-#{$val} {
      @media (min-width: #{$bp-value}) {
        margin: #{$val}px !important;
      }
    }
  }
  @each $letter, $side in $sides {
    .m#{$letter}-#{$val} {
      margin-#{$side}: #{$val}px !important;
    }
    @each $bp-name, $bp-value in $breakpoints {
      .m#{$letter}-#{$bp-name}-#{$val} {
        @media (min-width: #{$bp-value}) {
          margin-#{$side}: #{$val}px !important;
        }
      }
    }
  }
}

.m-0auto {
  margin: 0 auto !important;
}

/// ==================================================
/// OTHER HELPERS
/// ==================================================
.show-lg {
  @media (max-width: 1023.98px) {
    display: none;
  }
}

.hide-lg {
  @media (min-width: 1024px) {
    display: none;
  }
}

/// ==================================================
/// GENERAL STYLES
/// ==================================================
.is-title,
.is-headline,
.is-subtitle-lg,
.is-subtitle-md,
.is-subtitle-sm,
.is-subtitle-xs {
  font-weight: bold;
}

pfe-cta {
  --pfe-cta--BorderRadius: 0px;
  a {
    min-height: 46px;
    font-family: "CodecWarm Bold", "Open Sans", sans-serif;
    font-family: var(
      --pfe-cta--FontFamily,
      var(
        --pfe-theme--font-family--heading,
        "CodecWarm Bold",
        "Open Sans",
        sans-serif
      )
    );
    font-size: 16px;
    font-size: var(--pfe-cta--FontSize, var(--pfe-theme--font-size, 16px));
    padding: 0 17px !important;
  }
  span {
    min-height: 46px;
    padding: 0 17px !important;
    font-family: "CodecWarm Bold", "Open Sans", sans-serif;
    font-family: var(
      --pfe-cta--FontFamily,
      var(
        --pfe-theme--font-family--heading,
        "CodecWarm Bold",
        "Open Sans",
        sans-serif
      )
    );
    font-size: 16px;
    font-size: var(--pfe-cta--FontSize, var(--pfe-theme--font-size, 16px));
  }
}

pfe-card {
  --pfe-card__header--BackgroundColor: transparent;
  --pfe-card--spacing--vertical: 0px;
}

pfe-card:not(.card-with-header-image) {
  --pfe-card--PaddingTop: 0px;
  --pfe-card--Padding: 20px var(--pfe-card--PaddingRight)
    var(--pfe-card--PaddingBottom) var(--pfe-card--PaddingLeft);
}

html {
  --pfe-theme--surface--border-radius: 8px;
  scroll-behavior: smooth;
}

figure {
  margin: 0;
}

.pfe-l-grid.col-divider {
  > :not(:last-child) {
    position: relative;
    &:after {
      content: "";
      width: 1px;
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #d0d0d0;
    }
  }
  // if there add gutters, add half of the gap to positioning
  &.pfe-m-gutters {
    > :not(:last-child) {
      &:after {
        right: -8px;
      }
    }
  }
}

.pfe-m-all-6-col-on-xs {
  // this does not have any media query, differently from pfe-m-all-6-col which starts from 576px
  > * {
    width: auto;
    grid-column-start: auto;
    grid-column-end: span 6;
  }
}

// @TODO move this, container classes and helper classes to other css files in pfe-styles
// attribute that can be added to pfelements so that they are hidden until the wc is defined
[pfe-show-when-defined]:not([pfe-show-when-defined="false"]):not([pfelement]) {
  display: none;
}

body {
  background-color: #fff !important;
  min-height: 85px;
  --pfe-navigation--Height--actual: 85px;
}

.login {
  pfe-input {
    margin-bottom: 20px;
  }
}

pfe-shoulder-menu[open="false"] {
  display: none;
}

/// ===================================================
/// HOME PAGE
/// ===================================================
pfe-band.bg-transparent {
  --pfe-band--BackgroundColor: transparent;
}

.fleet-manager {
  p {
    font-size: 1rem;
    margin: 0;
  }
}

.homepage-hero {
  --pfe-carousel--Height: 600px;
}

pfe-band {
  --pfe-band--Width__aside--lg: 50%;
}

/// ===================================================
/// ARTICLE PAGE
/// ===================================================
.article-info {
  color: #a2a2a2;
}

.side-cards {
  pfe-card {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/// ===================================================
/// FAQ PAGE
/// ===================================================
.faq {
  &__intro {
    margin-top: 1.5rem;
  }
}

/// ===================================================
.newHomeCard {
  background-size: cover;
}

#root {
  > header {
    // make space for the header in fixed position, when present in the page
    height: var(--pfe-navigation--Height--actual, auto);
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}

.hidden {
  display: none !important;
}
@media screen and (min-width: 992px) {
  .headroom--top {
    -ms-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
  }
  .animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    will-change: transform, opacity;
    transition: all 0.5s, background 0s;
  }
}

header {
  height: 100% !important;
}
#hamburgerMenuMain {
  // margin-top: 100px;
  @media screen and (min-width: 375px) {
    margin-top: 81px;
  }
  @media screen and (min-width: 1025px) {
    margin-top: 100px;
  }
}

//rte styles
.Title01_Primary1_Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1554ff;
  font-stretch: normal;
  font-style: normal;
}
.Title01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: #1043cc;
}
.Title02_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  line-height: 1.14;
  color: #d1d2d4;
  font-stretch: normal;
  font-style: normal;
}
.Title02_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #abadaf;
}
.Title02_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Title02_Greyscale04_Light-Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Title02_Greyscale05_Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}
.Title-section01_Primary1_Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1554ff;
}
.Title-section01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1043cc;
}
.Title-section02_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Title-section02_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #abadaf;
}
.Title-section02_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Title-section02_Greyscale04_Light-Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Title-section02_Greyscale05_Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}
.Title-section-primary01_Primary1_Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #1554ff;
}
.Title-section-primary01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #1043cc;
}
.Title-section-primary02_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Title-section-primary02_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #abadaf;
}
.Title-section-primary02_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Title-section-primary02_Greyscale04_Light-Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Title-section-primary02_Greyscale05_Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #000000;
}
.Title-article01_Primary1_Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1554ff;
}
.Title-article01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1043cc;
}
.Title-article02_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Title-article02_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #abadaf;
}
.Title-article02_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Title-article02_Greyscale04_Light-Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Title-article02_Greyscale05_Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
}
.Subtitle-article01_Primary1_Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1554ff;
}
.Subtitle-article01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1043cc;
}
.Subtitle-article02_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Subtitle-article02_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #abadaf;
}
.Subtitle-article02_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Subtitle-article02_Greyscale04_Light-Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Subtitle-article02_Greyscale05_Black {
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #000000;
}
.Body01_Primary1_Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #1554ff;
}
.Body01_Primary2_Dark-Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #1043cc;
}
.Body02_Secondary01_Light-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Body02_Secondary02_Medium-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #abadaf;
}
.Body02_Secondary03_Dark-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Body02_Secondary04_Light-Black-2 {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Body02_Secondary05_Black {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #000000;
}
.Body101_Primary1_Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1554ff;
}
.Body101_Primary2_Dark-Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1043cc;
}
.Body102_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Body102_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #abadaf;
}
.Body102_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Body102_Greyscale04_Light-Black {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Body102_Greyscale05_Black {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
}
.Body301_Primary1_Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1554ff;
}
.Body301_Primary2_Dark-Blue {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1043cc;
}
.Body302_Greyscale01_Light-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #d1d2d4;
}
.Body302_Greyscale02_Medium-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #abadaf;
}
.Body302_Greyscale03_Dark-Grey {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4c4c4e;
}
.Body302_Greyscale04_Light-Black {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1e1e1d;
}
.Body302_Greyscale05_Black {
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
}
.Note01_Primary1_Blue {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1554ff;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note01_Primary2_Dark-Blue {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1043cc;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note02_Greyscale01_Light-Grey {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #d1d2d4;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note02_Greyscale02_Medium-Grey {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #abadaf;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note02_Greyscale03_Dark-Grey {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #4c4c4e;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note02_Greyscale04_Light-Black {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1e1e1d;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.Note02_Greyscale05_Black {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #000000;
  font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
}
.CTA01_Primary1_Blue {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #1554ff;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA01_Primary2_Dark-Blue {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #1043cc;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA02_Greyscale01_Light-Grey {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #d1d2d4;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA02_Greyscale02_Medium-Grey {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #abadaf;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA02_Greyscale03_Dark-Grey {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4c4c4e;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA02_Greyscale04_Light-Black {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1d;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
.CTA02_Greyscale05_Black {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
}
@media only screen and (max-width: 767px) {
  .Title01_Primary1_Blue {
    font-size: 34px;
    line-height: 1.12;
  }
  .Title01_Primary2_Dark-Blue {
    font-size: 34px;
    line-height: 1.12;
  }
  .Title02_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Title02_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Title02_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Title02_Greyscale04_Light-Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Title02_Greyscale05_Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #000000;
  }
  .Title-section01_Primary1_Blue {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Title-section01_Primary2_Dark-Blue {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Title-section02_Greyscale01_Light-Grey {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Title-section02_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Title-section02_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Title-section02_Greyscale04_Light-Black {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Title-section02_Greyscale05_Black {
    font-family: "CodecWarm-ExtraLight", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
  }
  .Title-section-primary01_Primary1_Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Title-section-primary01_Primary2_Dark-Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Title-section-primary02_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Title-section-primary02_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Title-section-primary02_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Title-section-primary02_Greyscale04_Light-Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Title-section-primary02_Greyscale05_Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
  }
  .Title-article01_Primary1_Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Title-article01_Primary2_Dark-Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Title-article02_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Title-article02_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Title-article02_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Title-article02_Greyscale04_Light-Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Title-article02_Greyscale05_Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000000;
  }
  .Subtitle-article01_Primary1_Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Subtitle-article01_Primary2_Dark-Blue {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Subtitle-article02_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Subtitle-article02_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Subtitle-article02_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Subtitle-article02_Greyscale04_Light-Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Subtitle-article02_Greyscale05_Black {
    font-family: "CodecWarm-Bold", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #000000;
  }
  .Body01_Primary1_Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Body01_Primary2_Dark-Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Body02_Secondary01_Light-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Body02_Secondary02_Medium-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Body02_Secondary03_Dark-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Body02_Secondary04_Light-Black-2 {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Body02_Secondary05_Black {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #000000;
  }
  .Body101_Primary1_Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Body101_Primary2_Dark-Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Body102_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Body102_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Body102_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Body102_Greyscale04_Light-Black {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Body102_Greyscale05_Black {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
  }
  .Body301_Primary1_Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1554ff;
  }
  .Body301_Primary2_Dark-Blue {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1043cc;
  }
  .Body302_Greyscale01_Light-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #d1d2d4;
  }
  .Body302_Greyscale02_Medium-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #abadaf;
  }
  .Body302_Greyscale03_Dark-Grey {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4c4c4e;
  }
  .Body302_Greyscale04_Light-Black {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1e1e1d;
  }
  .Body302_Greyscale05_Black {
    font-family: "CodecWarm-Regular", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
  }
}
strong {
  font-size: inherit !important;
}
